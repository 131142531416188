

import AxiosInstance, { getAxiosConfig } from "@/instance/instance";
import { GameType } from "@/routes/game/type";

export interface OperatedCitiesType{
    _id: string;
    countryId:string;
    city: string;
    locality_name: string;
    coordinates: {
        type:string;
        coordinates: number[]
    },
    zones:string;
    status: boolean;
    __v: number;
}
export interface GetOperatedCitiesDataType {
    data:{
        data:OperatedCitiesType[];
        message:string;
        status:string;
    }
 
}


export const getOperatedCities = async (): Promise<OperatedCitiesType[]> => {
    try {

        const response: GetOperatedCitiesDataType = await AxiosInstance.post(
            'v2/venue/active_cities',
            {},
            getAxiosConfig(true),
        );
   
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
