import React, { useState } from 'react';
//styles
import styles from './exploreComponent.module.scss';
import classNames from 'classnames';
import { motion } from 'framer-motion';
//components
import LocationSearch from './locationSearch/locationSearch';
//Image
import Image from 'next/image';

import LocationSearchMobile from './locationSearchMobile/locationSearchMobile';
import Seo from '@/components/common/seo';
import { BASEURL } from '@/utils/baseUrl';
import NavBar from '@/components/common/NavBar';
import Footer from '@/components/common/Footer';

export enum ExplorepageType {
  VENUES,
  GAMES
}

interface ExploreComponentPropType {
  pagetype: ExplorepageType;
}

const ExploreComponent = ({ pagetype }: ExploreComponentPropType) => {

  const [modalOpen, setOpen] = useState(false);

  return (
    <>
      <Seo meta_title={`Find Sports ${pagetype == ExplorepageType.VENUES ? 'Venues' : 'Games'} - Book courts near me | Turf Town`} description={pagetype == ExplorepageType.VENUES ? ' Discover and book the best sports courts near you with Turf Town' : 'Discover the best turf Games around you with Turf Town'} heading='Book Sports Venues - Quick & Easy Online Reservations' url={`${BASEURL}/sports-venues`} />

      <div className={classNames(styles['App1'], styles['p-0'])}>
        <NavBar />
        <div className={classNames(
          styles['container'],
          styles['column'],
          styles['align-center'],
          modalOpen && styles['h_w_100']
        )}>
          <>
            <div
              className={classNames(styles['sub_container'], styles['ph-16'], styles['mb-16'], styles['mobile_view'])}
            >
              <motion.div
                animate={{ y: 0, opacity: 1 }} initial={{ y: 150, opacity: 0 }}
                transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2, delay: 0.3 }}
                className={classNames(styles['discover_container'])}>
                <div className={classNames(styles['mt-36'])}>
                  <div className={classNames(styles['column'], styles['align-center'])}>
                    {pagetype == ExplorepageType.VENUES ? (<>
                      <h1 className={styles.discover}>
                        Discover the best <br />venues in <span className={styles.town}>Town.</span>
                      </h1>
                    </>) : (<>
                      <h1 className={styles.discover}>
                        Find games to play <br /><span className={styles.town}>nearby.</span>
                      </h1>
                    </>)}
                    <Image width={pagetype == ExplorepageType.VENUES ? 360 : 400} height={355} src={pagetype == ExplorepageType.VENUES ? '/images/explore_image.png' : '/images/explore-game.png'} alt={'player image'} />
                  </div>
                </div>
                <LocationSearchMobile onVisibleState={(status: boolean) => {
                  setOpen(status);
                }} type={pagetype} />
              </motion.div>
            </div>
          </>

          <div className={styles['desktop_View']}>
            <Image fill className={styles['gradient_image']} src={'/images/explore_gradient_background.png'} alt={'gradient image'} />
            <div
              className={classNames(styles['sub_container'], styles['ph-16'], styles['mb-28'], styles['zindex-1'])}
            >
              <motion.div
                animate={{ y: 0, opacity: 1 }} initial={{ y: 150, opacity: 0 }}
                transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2, delay: 0.3 }}
                className={classNames(styles['discover_container'])}>

                <div >
                  <div className={classNames(styles.discover_top)}>
                    {pagetype == ExplorepageType.VENUES ? (<>
                      <h1 className={styles.discover}>
                        Discover the best <br />venues in <span className={styles.town}>Town.</span>
                      </h1>
                      <div className={styles['mt-74']}>
                      </div>
                    </>) : (<>
                      <h1 className={styles.discover}>
                        Find games to play <span className={styles.town}>nearby.</span>
                      </h1>
                      <div className={styles['mt-74']}>
                      </div>
                    </>)}

                  </div>
                  <LocationSearch type={pagetype} />
                </div>

                <Image priority className={classNames(styles['image'], pagetype == ExplorepageType.GAMES && styles['image_sub'])} width={pagetype == ExplorepageType.VENUES ? 590 : 690} height={600} src={pagetype == ExplorepageType.VENUES ? '/images/explore_image.png' : '/images/explore-game.png'} alt={'player image'} />
              </motion.div>
            </div>
          </div>

        </div>

      </div>
      <Footer />
    </>

  )
}

export default ExploreComponent;