

import AxiosInstance, { getAxiosConfig } from '@/instance/instance';
import fetch from 'isomorphic-fetch';

export interface MapMyRevGeoType{
  houseNumber: string;
  houseName: string;
  poi: string;
  poi_dist: string;
  street: string;
  street_dist: string;
  subSubLocality: string;
  subLocality: string;
  locality: string;
  village: string;
  district: string;
  subDistrict: string;
  city: string;
  state: string;
  pincode: string;
  lat: string;
  lng: string;
  area:string;
  formatted_address: string;
       
}

export interface MapMyRevGeoDataType{
  data:{
    results: MapMyRevGeoType[]
  }
 
}

export const mapMyReverseGeo = async (
    lat: number,
    lng: number,
): Promise<MapMyRevGeoType[]> => {
    try {
      let token  = localStorage.getItem('accessToken');
      if(token){
        
      const response: MapMyRevGeoDataType = await AxiosInstance.post(
          'v2/venue/locate_me', 
          {
            lat:String(lat), 
            lng:String(lng),
            token:token
          },
          getAxiosConfig(false),
      );
   
      return response?.data?.results;
      }else{
        throw new Error('token is missing');
      }
       
    } catch (error) {
        console.error('getLatLong err', error);
        throw error;
    }
};



// export const mapMyReverseGeo = async (
//   lat: number,
//   lng: number,
// ): Promise<MapMyRevGeoType[]> => {
//   try {
//     let token  = localStorage.getItem('accessToken');
//     if(token){
//       const response = await fetch(`/api/revGeocode?lat=${lat}&lng=${lng}`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`
//           }});
//       const res: MapMyRevGeoDataType =  await response.json();
//       return res?.results;
//     }else{
//       throw new Error('token is missing');
//     }
     
//   } catch (error) {
//       console.error('getLatLong err', error);
//       throw error;
//   }
// };
