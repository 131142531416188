import Image from "next/image";
import classNames from "classnames";
import styles from './locationSearch.module.scss';
import { useCallback, useMemo, useState } from "react";
import Link from "next/link";
import { ExplorepageType } from "../exploreComponent";
interface SuggestionItemPropType {
    title: string;
    onClick: () => void;
    type: ExplorepageType;
}
const SuggestionItem = ({ title, onClick, type }: SuggestionItemPropType) => {
    const [loading, setLoading] = useState(false);


    const handleClick = useCallback(() => {
        setLoading(true);
        onClick && onClick();
        setTimeout(() => {
            setLoading(false);
        }, 5000)
    }, [onClick])


    const spinner = useCallback(() => {
        return (<>
            <div style={{ width: 'auto', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 10, position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, background: '#121212' }}>
                <svg className={styles["spinner_small"]} viewBox="0 0 50 50">
                    <circle className={styles["path"]} cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </div>
        </>)
    }, []);

    const link = useMemo(() => {
        if (type == ExplorepageType.VENUES) {
            return `/${title.trim().toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`;
        } else if (type == ExplorepageType.GAMES) {
            return `game-list/${title.trim().toLocaleLowerCase().replaceAll(' ', '-')}`;
        } else {
            return '/'
        }
    }, [title, type])

    return (
        <Link href={link} onClick={handleClick} className={classNames(styles['row'], styles['align-center'], styles['suggestion_cont'], styles['mr-16'], styles['cursor-pointer'], styles['capsule_mb'], loading && styles['justify-center'], styles['height_2'], styles['deco-none'])} >
            <Image height={16} width={16} src={'/svgs/location_purple.svg'} alt={'location'} />
            <p className={classNames(styles['suggestion_text'], styles['white1'], styles['ml-9'])}>{title}</p>
            {loading && spinner()}
        </Link>
    )
}

export default SuggestionItem;