import { motion } from "framer-motion";
import styles from './locationSearch.module.scss'
const Spinner = () => {
    return (
        <div style={{ width: 'auto', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <svg className={styles["spinner"]} viewBox="0 0 50 50">
                <circle className={styles["path"]} cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </div>

    );
};

export default Spinner;
