import React, { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
//styles
import styles from './locationSearchMobile.module.scss';
import classNames from 'classnames';
//Images
import Image from 'next/image';
import Loading from './loading';
import Spinner from './spinner';
import PopularCities from '../popularCities/popularCities';
import { MapMySearchType } from '@/apis/MapMySearch';
import { mapMyReverseGeo, MapMyRevGeoType } from '@/apis/mapMyReverseGeo';
import { ExplorepageType } from '../exploreComponent';

declare global {
  interface Window {
    mappls: any;
  }
}

export interface SearchType {
  eLoc: string;
  placeAddress: string
  placeName: string;
  suggester: string;
  type: string;
}

const LocationSearchMobile = ({ onVisibleState, type }: { onVisibleState: (status: boolean) => void, type: ExplorepageType; }) => {
  const router = useRouter();

  const [search, setSearch] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  // const [selectedLocation, setLocation] = useState<string>('');
  const [results, setResults] = useState<SearchType[]>([]);

  const callback = useCallback((d: any) => {

    setResults(d as SearchType[]);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    const getData = setTimeout(() => {
      if (search) {
        var placeOptions =
        {
          geolocation: true,
          pod: 'LC',
        };
        try {
          new window.mappls.search(search, placeOptions, callback);
        } catch (e) {

        }

      }
    }, 400);

    return () => clearTimeout(getData);
  }, [search]);

  const handleSearchClick = useCallback(
    (location: SearchType) => {
      let place = location?.placeName.trim().replaceAll(' ', '-');
      let city = location?.placeAddress.split(',')[0];
      if (type == ExplorepageType.VENUES) {

        router.push(`/${city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${place.toLocaleLowerCase()}/sports-venues`);
      } else if (type == ExplorepageType.GAMES) {
        router.push(`/game-list/${city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${place.toLocaleLowerCase()}`);
      }

    },
    [router, type]
  );

  const handleClick = useCallback(
    (location: MapMySearchType | MapMyRevGeoType) => {
      if (type == ExplorepageType.VENUES) {
        if (location?.locality && location?.city) {
          router.push(`${location?.city.toLocaleLowerCase()}/${location?.locality.toLocaleLowerCase()}/sports-venues`);
        } else if (location?.city) {
          router.push(`${location?.city.toLocaleLowerCase()}/sports-venues`);
        }
      } else if (type == ExplorepageType.GAMES) {
        if (location?.locality && location?.city) {
          router.push(`/game-list/${location?.city.toLocaleLowerCase()}/${location?.locality.toLocaleLowerCase()}`);
        } else if (location?.city) {
          router.push(`/game-list/${location?.city.toLocaleLowerCase()}`);
        }
      }

    },
    [router, type]
  );

  const getCity = useCallback(async (lat: number, lon: number) => {
    try {
      const res = await mapMyReverseGeo(lat, lon);
      if (res?.length > 0) {
        handleClick(res[0]);
      }

      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.error('search error', e);
    }
  }, [handleClick]);

  const getLocation = useCallback(() => {
    if (navigator.geolocation) {
      setSearch('');
      setResults([]);
      setSpinner(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getCity(position?.coords?.latitude, position?.coords?.longitude);

        },
        (error) => {
          setSpinner(false);
          if (error.code === 1) {
            alert('User denied geolocation access.');
          } else {
            alert('Error getting geolocation.');
          }
        }, {
        enableHighAccuracy: false,
      }
      );
    } else {
      setSpinner(false);
      alert('Geolocation is not supported.');
    }
  }, [getCity]);

  const handleinput = (event: { target: { value: any } }) => {
    setSearch(event.target.value);
  };


  const renderResultView = useCallback(() => {
    if (loading) return <Loading />;
    if (results.length > 0) {
      return (
        <>
          {search !== "" && (
            <>
              {results.map((r: SearchType) => {
                return (
                  <>
                    <div className={classNames(styles['row'], styles['width_100'], styles['mt-28'], styles['borderBottom'], styles["cursor-pointer"])} onClick={() => { handleSearchClick(r) }}>
                      <Image height={44} width={44} className={styles['mr-16']} priority src={'/svgs/location_pin_icon.svg'} alt='whitegps' />
                      <div className={classNames(styles['width_100'])}>
                        <div className={classNames(styles['headline'], styles['white1'])}>{r?.placeName}</div>
                        <div className={classNames(styles['Body'], styles['white3'], styles['mb-28'])} >{r?.placeAddress}</div>
                      </div>
                    </div>
                  </>
                )
              })}
            </>

          )}
        </>
      )
    } else {
      return <div className={classNames(styles['sectiontitle'], styles['white1'], styles['mb-44'])}>No results found</div>
    }
  }, [handleSearchClick, loading, results, search])

  const handleClear = useCallback(() => {
    setSearch('');
  }, [])

  return (
    <>
      <div className={classNames(styles['column'], styles['align-center'])}>
        <div className={classNames(styles['locate_button'])}>
          {spinner ? (
            <Spinner />
          ) : (
            <>
              <Image className={styles['mr-12']} height={20} width={20} src={'/svgs/green_gps.svg'} alt='gps' />
              <p
                onClick={getLocation}
                className={classNames(
                  styles['title'],
                  styles['cursor-pointer'],
                  styles['mr-16'],
                  styles['mt-4']
                )}
              >
                Use Current Location
              </p>
              <Image width={16} height={13} src={'/svgs/right_arrow_2.svg'} alt='gps' />
            </>
          )}
        </div>
      </div>
      <p className={classNames(styles['mb-20'], styles['mt-48'], styles['button_2'], styles['grey4'])}>CITIES WE OPERATE IN</p>
      <PopularCities type={type} />
    </>
  );
};



export default LocationSearchMobile;
