import React from 'react'
import styles from './footer.module.scss';
import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';

const Footer = () => {
    return (
        <div className={classNames(styles['container'])}>
            <div className={classNames(styles['subContainer'])}>
                <div className={classNames(styles['left'])}>
                    <Link href='/' >
                        <Image className={styles['logo']} width={112} height={62} src={'/images/Logowhite.png'} alt='logo' />
                    </Link>
                    <div className={classNames(styles['cont'])}>
                        <div className={classNames(styles['image_cont'], styles['mt-48'])}>
                            <Link href={'https://apps.apple.com/in/app/turf-town/id1490231308'} target='_blank'>
                                <Image width={191} height={70} src={'/landingimages/App store.svg'} className={classNames(styles['image'], styles['img_mr'], styles['apple_image'])} alt='logo' loading='lazy' />
                            </Link>
                            <Link href={'https://play.google.com/store/apps/details?id=com.turftown'} target='_blank'>
                                <Image width={191} height={70} className={classNames(styles['image'], styles['android_image'])} src={'/landingimages/gpay.svg'} alt='logo' loading='lazy' />
                            </Link>
                        </div>
                        <p className={classNames(styles['sub_6'], styles['white9'], styles['mt-54'], styles['text_shadow'])}>Designed for sport & made in Madras</p>
                    </div>

                </div>
                <div className={classNames(styles['right'])}>
                    <div className={classNames(styles['link_cont'])}>
                        <div className={classNames(styles.company_desktop_title)}>Company</div>
                        <Link href={'/contact'} className={styles['link_text']}>Contact Us</Link>
                        <Link target='_blank' href={"https://turftown.in/blog"} className={styles['link_text']}>Blog</Link>
                        <Link href={'/terms-of-service'} className={styles['link_text']}>Terms of Service</Link>
                        <Link href={'/privacy-policy'} className={styles['link_text']}>Privacy Policy</Link>
                    </div>

                    <div className={classNames(styles['link_cont'])}>
                        <div className={classNames(styles.company_desktop_title)}>Social</div>
                        <Link href={'https://www.instagram.com/turftown.in/?hl=en'} target='_blank' className={styles['link_text']}>Instagram</Link>
                        <Link href={'https://twitter.com/Turf_Town'} target='_blank' className={styles['link_text']}>Twitter</Link>
                        <Link href={'https://www.linkedin.com/company/turf-town/'} target='_blank' className={styles['link_text']}>LinkedIn</Link>
                        <Link href={'https://www.facebook.com/turftown.in/'} target='_blank' className={styles['link_text']}>Facebook</Link>
                    </div>
                </div>

                <div className={classNames(styles['mobile_cont'])}>
                    <div className={classNames(styles['image_cont'], styles['mt-48'])}>
                        <Link href={'https://apps.apple.com/in/app/turf-town/id1490231308'} target='_blank'>
                            <Image width={160} height={52} src={'/landingimages/App store.svg'} className={classNames(styles['image'], styles['mr-18'])} alt='logo' loading='lazy' />
                        </Link>
                        <Link href={'https://play.google.com/store/apps/details?id=com.turftown'} target='_blank'>
                            <Image width={152} height={60} className={classNames(styles['image'], styles['android_image'])} src={'/landingimages/gpay.svg'} alt='logo' loading='lazy' />
                        </Link>
                    </div>
                    <p className={classNames(styles['sub_6'], styles['mt-54'], styles['text_shadow'])}>Designed for sport & made in Madras</p>
                </div>
            </div>
        </div>
    )
}

export default Footer